import {navigate} from 'gatsby'

const NotFoundPage = () => {
  if (typeof window !== 'undefined') {
    navigate("/maintenance/");
    return null;
  } else {
    return null;
  }
};

export default NotFoundPage
